/** @format */

import React from "react";
import Wrapper from "./Team.styled";

import Member from "../../images/1.png";
import Member2 from "../../images/2.jpg";
import Member3 from "../../images/3.jpg";
import Member4 from "../../images/4.png";
import Member5 from "../../images/5.jpg";
import Member6 from "../../images/6.png";
import Member7 from "../../images/7.jpg";
import Member8 from "../../images/8.png";
import Member9 from "../../images/9.png";
import Member10 from "../../images/10.png";
import Member11 from "../../images/11.png";
import Member12 from "../../images/12.png";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Autoplay } from "swiper";
import SwiperCore, { Navigation } from "swiper";

function Team() {
  SwiperCore.use([Navigation]);
  return (
    <Wrapper>
      <div className="team" id="team">
        <div className="container">
          <div className="row reverse">
            <div className="col-md-6">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                loop={true}
                autoplay={{
                  delay: 1500,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  {" "}
                  <div className="sigleitemMember">
                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member4}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Alao Luqman Omotayo</h3>
                        <p>Artist</p>
                      </div>
                    </div>

                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member6}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Bimbo Adenugba</h3>
                        <p>Artist</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="sigleitemMember">
                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member8}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Dr Bolaji Ogunwo Phd</h3>
                        <p>Artist</p>
                      </div>
                    </div>

                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member12}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Steven Adebanji</h3>
                        <p>Artist</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="sigleitemMember">
                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member9}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Duke Asidere</h3>
                        <p>Artist</p>
                      </div>
                    </div>

                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member2}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Emmanuel Dudu</h3>
                        <p>Artist</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <div className="sigleitemMember">
                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member3}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Erikan Ekefrey</h3>
                        <p>Artist</p>
                      </div>
                    </div>

                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member10}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Greg Bailey</h3>
                        <p>Artist</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="sigleitemMember">
                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>way + creative solutions</h3>
                        <p>Technial Partner</p>
                      </div>
                    </div>

                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member7}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Syed M. Ali</h3>
                        <p>Block Chain Developer</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <div className="sigleitemMember">
                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member3}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Erikan Ekefrey</h3>
                        <p>Artist</p>
                      </div>
                    </div>

                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member5}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Nduka Freeborn Onyia</h3>
                        <p>Artist</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="sigleitemMember">
                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member5}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Nduka Freeborn Onyia</h3>
                        <p>Artist</p>
                      </div>
                    </div>

                    <div className="cardItem">
                      <div className="Memberimg">
                        <img
                          src={Member12}
                          className="img-fluid"
                          alt="team member"
                        />
                      </div>
                      <div className="title">
                        <h3>Steven Adebanji</h3>
                        <p>Artist</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-md-6 swiperCOl">
              <div className="swiperSinglItem">
                <div className="swiperContent">
                  <h2>
                    {" "}
                    Cali-Afrocentric Volume 1 seeks to project authentic
                    African/ world art
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Team;
