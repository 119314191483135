/** @format */

import styled from "styled-components";

const Wrapper = styled.div`
  .team {
    padding: 50px 0px 70px 0px;
  }
  .sigleitemMember {
    display: grid;

    flex-wrap: wrap;
    margin-bottom: 130px;
    grid-template-columns: 1fr 1fr;
  }
  .cardItem {
    text-align: center;
  }
  .Memberimg {
  }
  .Memberimg img {
    border-radius: 10px;
    width: 90%;

    object-fit: contain;
  }
  .title {
  }
  .title h3 {
    color: #d8f802;
    font-weight: 500;
  }
  .title h3 p {
    font-size: 18px;
    margin-top: 10px;
  }
  .title h3 p span {
  }
  .col-md-6 {
    position: relative;
  }
  .boxShadow {
    position: absolute;
    width: 190px;
    height: 170px;
    top: 0;
    left: 0;
    -webkit-filter: blur(110px);
    filter: blur(180px);
    box-shadow: -7px -40px 102px 39px rgb(28 81 254 / 75%);
    -webkit-box-shadow: -7px -40px 102px 39px #a3b512;
    -moz-box-shadow: -7px -40px 102px 39px rgba(28, 81, 254, 0.75);
    z-index: 2;
  }
  .swiperContent {
    padding-bottom: 170px;
    padding-left: 70px;
  }
  .swiperContent h2 {
    color: #d8f802;
    font-size: 28px;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
    top: auto;
    bottom: 0;
    width: 140px;
    height: 50px;
    color: white;
    z-index: 875;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 10px;
    right: auto;
    top: auto;
    bottom: 0;
    width: 140px;
    height: 50px;
    color: white;
    z-index: 875;
  }
  .swiper-pagination-bullet {
    width: 50px;
    height: 3px;
    border-radius: 42px;
  }
  .swiper-pagination-bullet-active {
    background-color: white;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 10px;
    left: 23%;
    width: 50%;
  }
  .swiper-slide-active {
    z-index: -54545;
  }
  @media (max-width: 992px) {
    .reverse {
      flex-direction: column-reverse;
    }
  }
  @media (max-width: 575.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .swiperContent {
      margin-top: 0px;
      padding-bottom: 50px;
      padding-left: 30px;
    }
    .sigleitemMember {
      justify-content: space-evenly;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 110px;
    }
    .swiperContent h2 {
      font-size: 16px;
    }

    .swiper-pagination-bullet {
      width: 20px;
      height: 6px;
      border-radius: 42px;
    }
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      left: 0px;
      right: auto;
      top: auto;
      bottom: 0;
      width: 91px;
      height: 50px;
      color: white;
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
      right: 0px;
      left: auto;
      top: auto;
      bottom: 0;
      width: 91px;
      height: 50px;
      color: white;
    }
  }
  .swiperCOl {
    position: relative;
  }
  .boxShadow2 {
    position: absolute;
    width: 190px;
    height: 170px;
    left: auto;

    right: 0;
    -webkit-filter: blur(110px);
    filter: blur(140px);
    box-shadow: -7px -40px 102px 39px rgb(28 81 254 / 75%);
    -webkit-box-shadow: -7px -40px 102px 39px #a3b512;
    -moz-box-shadow: -7px -40px 102px 39px rgba(28, 81, 254, 0.75);
    z-index: -2;
    top: auto;
    bottom: 50;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .swiperContent {
      margin-top: 0px;
      padding-left: 10px;
      padding-bottom: 50px;
    }
    .sigleitemMember {
      margin-bottom: 110px;
    }
    .sigleitemMember {
      justify-content: space-evenly;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .swiperContent {
      margin-top: 0px;
      padding-bottom: 50px;
    }
    .sigleitemMember {
      justify-content: space-evenly;
    }
    .sigleitemMember {
      margin-bottom: 110px;
    }
    .sigleitemMember {
      justify-content: space-evenly;
    }
  }
  @media (min-width: 1200px) {
    .Memberimg img {
      width: 90%;
      height: 400px;
      object-fit: cover;
    }
  }
`;

export default Wrapper;
